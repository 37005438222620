export {};

/**
 * Attentive's hosted sign up units are rendered in an iframe. It is not clearly documented but we
 * were able to figure out that Attentive posts messages from the iframe when various things happen.
 * For example, when a sign up unit is displayed, when a step changes, or when someone enters text
 * into an input and submits.
 *
 * This looks at all messages and examines which are coming from the Attentive iframe. When an
 * Attentive message arrives, this emits certain events.
 */
function onMessageEvent(event: MessageEvent) {
  type Detail = WindowEventMap['attentive-lead-captured']['detail'];

  if (isAttentiveMessageEvent(event)) {
    const action = event.data.__attentive;

    if (isEmailLeadAction(action) && action.email) {
      const captureEvent = new CustomEvent<Detail>('attentive-lead-captured', {
        detail: {
          action: action.action,
          creative_id: action.creativeId,
          creative_name: action.creativeName,
          email: action.email,
          visitor_id: action.visitorId
        }
      });
      dispatchEvent(captureEvent);
    } else if (isLeadAction(action) && action.phone) {
      const captureEvent = new CustomEvent<Detail>('attentive-lead-captured', {
        detail: {
          action: action.action,
          creative_id: action.creativeId,
          creative_name: action.creativeName,
          email: action.email,
          phone: action.phone,
          user_id: action.userId,
          visitor_id: action.visitorId
        }
      });
      dispatchEvent(captureEvent);
    }
  }
}

interface AttentiveMessageEvent extends MessageEvent {
  data: {
    __attentive: Record<string, any>;
  };
}

/**
 * Returns whether the given value looks like a message event sourced from the Attentive iframe.
 *
 * Attentive's iframe sends various kinds of messages. What seems to be the common pattern is that
 * data is an object containing a property named "__attentive". That property then contains an
 * object that seems to represent some kind of action, such as resized iframe, opened sign up unit,
 * or captured lead. So here we are just concluding that once we observe an "__attentive" key we
 * are dealing with some kind of Attentive message.
 *
 * There may be others, we did not do exhausting experimentation, we are only interested in a select
 * number of messages.
 */
function isAttentiveMessageEvent(value: any): value is AttentiveMessageEvent {
  return isMessageEvent(value) && value.isTrusted && value.origin === 'https://creatives.attn.tv' &&
    value.data?.__attentive;
}

function isMessageEvent(value: any): value is MessageEvent {
  return value?.type === 'message';
}

/**
 * Represents one type of data from a message event fired from the Attentive sign up unit iframe.
 *
 * Not all properties are explicitly typed here, only the ones we think are useful.
 */
interface EmailLeadAction {
  action: 'EMAIL_LEAD';
  creativeId: string;
  creativeName: string;
  email: string;
  visitorId: string;
}

function isEmailLeadAction(value: any): value is EmailLeadAction {
  return value?.action === 'EMAIL_LEAD';
}

/**
 * Represents one type of data from a message event fired from the Attentive sign up unit iframe
 */
interface LeadAction {
  action: 'LEAD';
  anonymousId: string;
  attentiveAPI: string;
  attnDomain: string;
  companyExternalId: string;
  countdownTimerFilter: unknown;
  creativeId: string;
  creativeName: string;
  email: string;
  encodedSubscriberExternalId: string;
  environment: string;
  hasEmail: boolean;
  impressionId: string;
  impressionPageType: string;
  isSubscriber: boolean;
  mobile: boolean;
  pageview: string;
  pageviewRuleLowerBound: string;
  phone: string;
  productVariants: unknown;
  sourceUrl: string;
  subscriberChannels: unknown[];
  templateId: number;
  thirdPartyAnalytics: Record<string, any>;
  userEmail: unknown;
  userId: string;
  visitorId: string;
}

function isLeadAction(value: any): value is LeadAction {
  return value?.action === 'LEAD';
}

function main() {
  // Prevent the listener running in the liquid from appending new events
  removeEventListener('message', attentive_event_listener);

  // Replay any events in the queue
  for (const event of attentive_event_queue) {
    if (isMessageEvent(event)) {
      try {
        onMessageEvent(event);
      } catch (error) {
        console.warn(error);
      }
    }
  }

  // Clear the queue to deref objects and for idempotency
  attentive_event_queue = [];

  // Begin listening for events that fire after this has run
  addEventListener('message', onMessageEvent);
}

main();
